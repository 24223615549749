import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Equal from 'equal-vue'
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import VueExcelEditor from 'vue3-excel-editor'
import { dragscrollNext } from "vue-dragscroll";
import 'equal-vue/dist/style.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'material-icons/iconfont/material-icons.css';
import CookieConsentVue from './CookieConsent.js'
import ConfirmationService from 'primevue/confirmationservice';

createApp(App)
    .use(store)
    .use(router)
    .use(Equal)
    .use(PrimeVue)
    .use(ToastService)
    .use(CookieConsentVue, {
        categories: {
            necessary: {
                enabled: true,  // this category is enabled by default
                readOnly: true  // this category cannot be disabled
            },
            performance: {}
        },

        language: {
            default: 'en',
            translations: {
                en: {
                    consentModal: {
                        title: 'We use cookies',
                        description: 'We use cookies to improve your experience on our site. By continuing to browse, you agree to our use of cookies. ',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        showPreferencesBtn: 'Manage Preferences and Cookies Policy'
                    },
                    preferencesModal: {
                        title: 'Manage cookie preferences and cookies policy',
                        acceptAllBtn: 'Accept all',
                        acceptNecessaryBtn: 'Reject all',
                        savePreferencesBtn: 'Accept current selection',
                        closeIconLabel: 'Close modal',
                        sections: [
                            {
                                "title": "How We Use Cookies",
                                "description": "We use cookies for a variety of reasons detailed below. Unfortunately, in most cases, there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not, in case they are used to provide a service that you use."
                            },
                            {
                                "title": 'Strictly Necessary cookies',
                                "description": 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                                "linkedCategory": 'necessary'
                            },
                            {
                                "title": 'Performance and Personalization',
                                "description": 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                "linkedCategory": 'performance'
                            },
                            {
                                "title": "Disabling Cookies",
                                "description": "You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of this site. Therefore, it is recommended that you do not disable cookies."
                            },
                            {
                                "title": "The Cookies We Set",
                                "description": "If you create an account with us, then we will use cookies for the management of the signup process and general administration. These cookies will usually be deleted when you log out; however, in some cases, they may remain afterwards to remember your site preferences when logged out."
                            },
                            {
                                "title": "Login Related Cookies",
                                "description": "We use cookies when you are logged in so that we can remember that you are logged in. This prevents you from having to log in every single time you visit a new page. These cookies are typically removed or cleared when you log out to ensure that you can only access restricted features and areas when logged in."
                            },
                            {
                                "title": "Essential Cookies",
                                "description": "Essential cookies and scripts are essential for our website to function. They allow visitors to move around our website and use its basic features, such as accessing secure areas of the website, opening navigation, and displaying content."
                            },
                            {
                                "title": "Analytics",
                                "description": "Analytics allow us to count visits and traffic sources to the website, so that we can measure and improve the performance of our site. Analytics let us know which pages are the most and least popular and see how visitors move around the site. All of the information collected from analytics cookies is aggregated so it is anonymous."
                            },
                            {
                                "title": "Personalization",
                                "description": "These cookies and scripts allow us to remember choices you make (such as theme or last scenario/signal list selected)"
                            },
                            {
                                "title": 'More information',
                                "description": 'For any queries in relation to my policy on cookies and your choices, please <a target=_blank href="https://support.easygen.org/en-US/new-ticket">contact us</a>'
                            }
                        ]
                    }
                }
            }
        }
    })
    .use(VueExcelEditor)
    .use(ConfirmationService)
    .directive('tooltip', Tooltip)
    .directive('dragscroll', dragscrollNext)
    .mount('#app')
