import { signInWithEmailAndPassword, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword, sendEmailVerification, sendPasswordResetEmail, getIdToken } from 'firebase/auth'
import { auth } from '../firebaseConfig'
import router from '../router'


export default {
    state() {
        return {
            user: {
                loggedIn: false,
                data: null
            },
            license: null
        }
    },
    getters: {
        user(state) {
            return state.user
        },
        accessToken(state) {
            return state.user.data ? state.user.data.accessToken : null
        },
        license(state) {
          return state.license
        }
        
    },
    mutations: {
        SET_LOGGED_IN(state, value) {
          state.user.loggedIn = value;
        },
        SET_USER(state, data) {
          state.user.data = data;
        },
        SET_ACCESS_TOKEN(state, data) {
          state.user.data.accessToken = data
        },
        SET_LICENSE(state, data) {
          state.license = data
        }
    },
    actions: {
        async logIn({commit}, payload) {
            const response = await signInWithEmailAndPassword(auth, payload.email, payload.password)
            if (response.ok) {
              commit('SET_USER', response.user)
            } 
        },

        async logOut(context){
            await signOut(auth)
            router.push('/')
            context.commit('SET_USER', null)
            window.location.reload();
        },
    
        async fetchUser(context, user) {
          context.commit("SET_LOGGED_IN", user !== null);
          if (user) {
            context.commit("SET_USER", {
              user
            });
          } else {
            context.commit("SET_USER", null);
          }
        },
        
        async updatePassword({getters}, payload) {
          const user = auth.currentUser
          if (user) {
            const email = getters.user.data.email
            const credential = EmailAuthProvider.credential(email, payload.current_password)
            await reauthenticateWithCredential(user, credential)
            await updatePassword(user, payload.new_password)
          }
        },

        async verifyEmail() {
          const user = auth.currentUser
          if (user) {
              sendEmailVerification(user)
          }
        },

        async refreshIdToken({state, commit}) {
          const token = await getIdToken(state.user.data, true)
          commit('SET_ACCESS_TOKEN', token)
        },

        async sendRestartPasswordEmail(_, payload) {
          await sendPasswordResetEmail(auth, payload.email)
        },

        async getLicenseDetails({getters, commit}) {
          try {
            const response = await fetch(getters.mainDatabase + 'licence' + getters.databaseEndpoint)
            const responseData = await  response.json()
            commit('SET_LICENSE', responseData)
            
          } catch(err) {
            console.log('Failed getting license info ' + err)
          }


        }

    }
}