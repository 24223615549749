import { timeDifference, timeAgo } from '../utils.js'
export default {
    state() {
        return {
            start_stop_server_ip: 'https://control.easysim.link',
            vm: {
                uid: null,
                url: null,
                sra_password: null,
                status: 'unknown',
                shutdown_time: null,
                shutdown_time_to: null,
                version: null
            },
            model_list: [],
            device_list: [],
            current_model: 'No data',
            show_shutdown_dialog: false,
            download_files: null
        }
    },
    mutations: {
        setVmUid(state, uid) {
            state.vm.uid = uid
        },
        setVmIp(state, ip) {
            state.vm.ip = ip
        },
        setToolkitIp(state, ip) {
            state.vm.toolkit = ip
        },
        setVmStatus(state, payload) {
            state.vm.status = payload.data.state || 'unknown'
            state.vm.url = payload.data.sra_url
            state.vm.shutdown_time = payload.data.shutdown_at_time
            state.vm.shutdown_time_to = payload.data.shutdown_at_time ? timeAgo(payload.data.shutdown_at_time) : null
            state.vm.sra_password = payload.data.sra_password
            state.vm.version = payload.data.version
        },
        setModelList(state, models) {
            state.model_list = models
        },
        setCurrentModel(state, model) {
            state.current_model = model ? model : 'None'
        },
        setDeviceList(state, devices) {
            state.device_list = devices
        },
        setShutDownDialog(state, value) {
            state.show_shutdown_dialog = value
        },
        setDownloadFiles(state, files) {
            state.download_files = files
        }
    },
    actions: {
        async getVmUid({ getters, commit }) {
            const response = await fetch(getters.mainDatabase + 'vm' + getters.databaseEndpoint, {
                headers: {
                    'Authorization': 'Bearer ' + getters.accessToken
                },
                method: "GET"
            })
            const responseData = await response.json()

            if (response.ok) {
                commit('setVmUid', responseData)
            }
            else {
                throw new Error('Failed when getting VmUID')
            }
        },
        async getVmStatus({ getters, commit }) {

            try {
                const response = await fetch(getters.startStopServerIp + '/vmdata?env_id=' + getters.vmId, {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                })
                const responseData = await response.json()
                if (response.ok) {
                    commit('setVmStatus', responseData)
                    if (!responseData.data.shutdown_at_time) {
                        commit('setShutDownDialog', false)
                        return
                    }
                    if (timeDifference(responseData.data.shutdown_at_time) < 900) {
                        commit('setShutDownDialog', true)
                    } else {
                        commit('setShutDownDialog', false)
                    }
    
                }
                else {
                    throw new Error('Failed when getting VmStatus')
                }
            } catch(err) {
                commit('setVmStatus', { data: {
                    url: null,
                    sra_password: null,
                    state: 'unknown',
                    shutdown_time: null,
                    shutdown_time_to: null,
                    version: null
                }
                })
                console.log(`Failed when getting vmdata\n(${err})` )
            }
                
        },
        async startVm({ getters }) {
            try {
                const response = await fetch(getters.startStopServerIp + '/startvm?env_id=' + getters.vmId, {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                })
    
                if (response.ok) {
                    const responseData = await response.text()
                    return responseData
                }
                else {
                    throw new Error('Failed when starting VM') 
                }
            } catch(err) {
                console.log(err)
            }
        },
        async stopVm({ getters, commit }) {
            try {
                const response = await fetch(getters.startStopServerIp + '/stopvm?env_id=' + getters.vmId, {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                })
    
                if (response.ok) {
                    const responseData = await response.text()
                    commit('setCurrentModel', 'No data')
                    commit('setModelList', [])
                    return responseData
                }
                else {
                    throw new Error('Failed when stopping VM') 
                }
            } catch(err) {
                console.log(err)
            }
        },
        async restartVm({ getters }) {
            try {
                const response = await fetch(getters.startStopServerIp + '/restart?env_id=' + getters.vmId, {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                })
    
                if (response.ok) {
                    const responseData = await response.text()
                    return responseData
                }
                else {
                    throw new Error('Failed when restarting VM') 
                }
            } catch(err) {
                console.log(err)
            }
        },
        async extendSession({ getters, commit, dispatch }) {
            try {
                await dispatch('refreshIdToken')
                const response = await fetch(getters.startStopServerIp + '/extendsession?env_id=' + getters.vmId, {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                })
    
                if (response.ok) {
                    const responseData = await response.json()
                    commit('setVmStatus', responseData)
                }
                else {
                    throw new Error('Failed when extending VM session') 
                }
            } catch(err) {
                console.log(err)
            }
        },
        async getModelList( {getters, commit} ) {
            try {
                const response  = await fetch(getters.httpsVmIp + '/listModels', {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                })
                if (response.ok) {
                    const responseData = await response.json()
                    commit('setModelList', responseData)
                } else {
                    throw new Error('Failed getting model list')
                }
            } catch(err) {
                commit('setModelList', [])
                console.log('Simubox server not started yet')
            }
        },
        async getCurrentModel( { getters, commit } ) {
            try {
                const response = await fetch(getters.httpsVmIp + '/currentSimulation', {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                })
                if (response.ok) {
                    const responseData = await response.text()
                    commit('setCurrentModel', responseData)
                } else {
                    throw new Error('Failed getting current model')
                }
            } catch(err) {
                commit('setCurrentModel', 'No data')
                console.log('Simubox server not started yet')
            }
        },
        async getDownloadList( { getters, commit } ) {
            const response = await fetch(getters.httpsVmIp + '/listDownloadFiles', {
                headers: {
                    'Authorization': 'Bearer ' + getters.accessToken
                },
                method: "GET"
            })
            if (response.ok) {
                const responseData = await response.json()
                commit('setDownloadFiles', responseData)
            } else {
                throw new Error('Failed getting download list')
            }
        },
        async downloadFile( { getters }, fileName ) {
            const response = await fetch(getters.httpsVmIp + `downloadFile/${fileName}`)
            if (!response.ok) {
                const responseData = await response.json()
                window.alert(responseData.message + '\nIf you need this file, please contact us.')
                return
            }
            const a = document.createElement("a");
            a.href = getters.httpsVmIp + `downloadFile/${fileName}`
            a.download = true
            a.click()
            
        },
        async uploadFile({ getters }, formData) {
            const response = await fetch(getters.httpsVmIp + '/uploadFile', {
                headers: {
                    'Authorization': 'Bearer ' + getters.accessToken
                },
                body: formData,
                method: "POST"
            })
            if (!response.ok) {
                const responseData = await response.json()
                window.alert(responseData.message + '\nIf you need this file, please contact us.')
                return
            }

        },
        async startModel({getters}, model_name) {
            const response = await fetch(getters.httpsVmIp + '/changeSimulation?simulation_name=' + model_name, {
                headers: {
                    'Authorization': 'Bearer ' + getters.accessToken
                },
                method: "GET"
            })
            if (response.ok) {
                const response = await fetch(getters.httpsVmIp + 'startSimu')
                if (response.ok) {
                    return true
                } else {
                    throw new Error('Failed starting model')
                }
            } else {
                throw new Error('Failed switching model')
            }
        },
        async getDeviceList( { commit, getters } ) {
            try {
                const response = await fetch(getters.httpsVmIp + '/netsimStatus', {
                    headers: {
                        'Authorization': 'Bearer ' + getters.accessToken
                    },
                    method: "GET"
                }) 
                if (response.ok) {
                    const responseData = await response.json()
                    commit('setDeviceList', responseData)
                }
                else {
                    throw new Error('Failed getting device list')
                }
            } catch(err) {
                commit('setDeviceList', [])
                console.log('failed getting device list')
            }
        }
    },
    getters: {
        startStopServerIp(state) {
            return state.start_stop_server_ip
        },
        vmId(state) {
            return state.vm.uid || 'Simubox not assigned'
        },
        vmIp(state) {
            return state.vm.uid ? `${state.start_stop_server_ip}/route/${state.vm.uid}` : 'Simubox not assigned'
        },
        vmRdp(state) {
            if (state.vm.status !== 'running') {
                return 'Simubox unreachable'
            }
            if (!state.vm.url) {
                return 'Simubox not ready'
            }
            return state.vm.url
        },
        httpsVmIp(state) {
            return `${state.start_stop_server_ip}/route/${state.vm.uid}`
        },
        vmStatus(state) {
            return state.vm.status
        },
        vmShutdownTime(state) {
            return state.vm.shutdown_time ? new Date(state.vm.shutdown_time) : null
        },
        vmShutdownTimeTo(state) {
            return state.vm.shutdown_time_to || 'never'
        },
        vmSraPassword(state) {
            return state.vm.sra_password
        },
        vmVersion(state) {
            return state.vm.version || 'No data'
        },
        modelList(state) {
            return state.model_list
        },
        currentModel(state) {
            return state.current_model
        },
        deviceList(state) {
            return state.device_list
        },
        showShutdownDialog(state) {
            return state.show_shutdown_dialog
        },
        downloadFiles(state) {
            return state.download_files
        }
    }
}