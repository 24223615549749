import { createStore } from "vuex";
import firebaseModule from './firebase.js'
import zoomModule from './zoom.js'
import sequencerModule from './sequencer.js'
import monitorModule from './monitor.js'
import authModule from './auth.js'
import vmModule from './vm.js'
import storageModule from './storage.js'

export default createStore({
  modules: {
    firebase: firebaseModule,
    zoom: zoomModule,
    sequencer: sequencerModule,
    monitor: monitorModule,
    auth: authModule,
    vm: vmModule,
    storage: storageModule
  },
  state() {
    return {
      app_version: 'easYsim SimHMI 3.2.5-hf2',
      signals: [],
      virtual_machine_ip: "",
      simulation_ip: "",
      cycles: -1,
      cycles_confired: -1,
      getting_signals: false,
      flag: false,
      signal_show: true,
      grid_show: false,
      status: 0,
      promise_queue: [],
      stop_getting: false,
      localhost_connection: false,
      connection_check_interval: null,
      signal_polling_interval: null,
      refresh_ratio: 250,
      simu_connect_ip: '192.168.111.100',
      simu_connect_port: '443',
      connected_model: 'None',
      latency: 0,
      selected_refresh_ratio: 0,
      disconnect_flag: 0
    };
  },
  mutations: {
    //update actuall signal list
    refreshSignals(state, payload) {
      if (payload.signals) {
        state.signals = payload.signals
      } 
    },
    //set new virutal machine IP
    setIpAdresses(state,payload) {
      //zmiana
      state.virtual_machine_ip = payload.ip + '/api/'
      state.simulation_ip = payload.ip + '/api/'
    },
    clearIpAdresses(state) {
      state.virtual_machine_ip = null,
      state.simulation_ip = null,
      state.current_model = '',
      state.model_list = []
    },
    //set status of connection
    setStatus(state,payload) {
      state.status = payload.status
      state.connected_model = payload.connected_model
      state.selected_refresh_ratio = payload.selected_refresh_ratio
    },
    setCurrentModels(state, payload) {
      state.current_model = payload.current_model
      state.model_list = payload.model_list
    },
    setQueue(state, payload) {
      if (payload.action === 'push') {
        state.promise_queue.push(null)
      } else {
        state.promise_queue.shift()
      }
    },
    setLatency(state, payload) {
      state.latency = payload.latency
    }

  },       
  actions: {
    //fetching signal list from flask server
    //used in Configuration.vue
    getSignals({commit, getters}) {
          var startDate = new Date();
          if (getters.queueLength <= 10) {
            commit('setQueue',{
              action: 'push'
            })
            fetch(getters.simulationIp + "v2/signalList?method=json")
              .then((res) => res.json())
              .then((res) => {
                var endDate = new Date();
                commit('setQueue',{
                  action: 'shift'
                })
                commit('setLatency', {
                  latency: (endDate.getTime() - startDate.getTime())
                })
                commit('refreshSignals', {
                  signals: res.signals,
                })
              })
              .catch((err) => {
                console.log(err)
                commit('setQueue',{
                  action: 'shift'
                })
              });
          } else {
            return 
          }  
    },
    startPollingSignals({state, dispatch}) {
      state.signal_polling_interval = setInterval(() => {
        dispatch('getSignals')
      }, state.refresh_ratio);
    },
    stopPollingSignals({state}) {
      clearInterval(state.signal_polling_interval)
    },
    //request to start simulation
    //used in NavButtons.vue
    async startSimulation({getters,state}) {

      state.stop_getting = false

      if (!state.localhost_connection) {
    
        const response = await fetch(getters.virtualMachineIp + 'v2/checkSimu')
        const responseData = await response.json()

        if (responseData === false) {
          await fetch(getters.virtualMachineIp + 'v2/startSimu')
        }
        await fetch(getters.simulationIp + "v2/signal/SimuRunning?cycles=" + getters.cyclesNumber)
      } else {
        await fetch(getters.simulationIp + "v2/signal/SimuRunning?cycles=" + getters.cyclesNumber)
      }
    },
    //used in NavButtons.vue
    stopSimulation({getters}) {
      fetch(getters.simulationIp + "v2/signal/SimuStop")
    },
    //used in SimuItemPanel.vue, EasyGenPanel.vue, Braker.vue
    async setSignal({getters}, payload) {
      if (getters.simulationIp === '') {
        return
      }
      const response = await fetch(getters.simulationIp + 'v2/signal/setValue?signal=' + payload.signal_name + '&value=' + payload.val + "&cycles=" + payload.cycles)
      
      if (!response.ok) {
        const error = new Error('Failed to set signal, try again later')
        throw error;
      }
    },
    //used in Configuration.vue
    async connectToSimulation({state, commit, dispatch},payload) {
      try {
        commit('setStatus', {
          status: 1,
          connected_model: 'None',
          selected_refresh_ratio: 0
        })
        const response = await fetch(`${payload.ip}/api/v2/Get_RMS_Number?method=json`)
        const responseData = await response.json()
        if (response.ok) {
          console.log('Succesfully connected to simulation: ' + responseData)

          commit('setIpAdresses', {ip: payload.ip, port: payload.port})
          commit('setStatus', {
            status: 2, 
            connected_model: responseData, 
            selected_refresh_ratio: state.refresh_ratio
          })
          dispatch('startPollingSignals')

          state.connection_check_interval = setInterval(() => {
            dispatch('connectionCheck', {ip: payload.ip, port: payload.port})
          }, 5000);
        }
      } catch(e) {
        commit('setStatus', {
          status: 3, 
          connected_model: 'None',
          selected_refresh_ratio: 0
        })
        console.log('Failed when connecting to simulation ')
        console.log(e)
      }
    },
    async connectionCheck({state, commit, dispatch}, payload) {
      try {
        const response = await fetch(`${payload.ip}/api/v2/Get_RMS_Number?method=json`)
        const responseData = await response.json()
        if (response.ok) {
          commit('setStatus', {
            status: 2, 
            connected_model: responseData,
            selected_refresh_ratio: state.refresh_ratio
          })
          state.retries = 0
          state.disconnect_flag = 0
        } else {
          throw new Error('Failed to connect to simulation')
        }
      } catch (e) {
        if (state.disconnect_flag < 3) {
          state.disconnect_flag++
          return
        }
        if (state.retries < 5) {
          commit('setStatus', {
            status: 4, 
            connected_model: `Attemp ${state.retries}...`, 
            selected_refresh_ratio: state.refresh_ratio
          })
          state.retries++
        } else {
          commit('setStatus', {
            status: 3, 
            connected_model: 'None',
            selected_refresh_ratio: 0
          })
          commit('setLatency', {
            latency: 0
          })
          commit('refreshSignals', {signals: []})
          console.log('Failed when connecting to simulation ', e)
          dispatch('stopPollingSignals')
          clearInterval(state.connection_check_interval)
        }

      }
    },
    disconnectFromVm({state, commit, dispatch}) {
      commit('setStatus', {
        status: 0, 
        connected_model: 'None',
        selected_refresh_ratio: 0
      })
      commit('setLatency', {
        latency: 0
      })
      commit('clearIpAdresses')
      commit('refreshSignals', {signals: []})
      dispatch('stopPollingSignals')
      clearInterval(state.connection_check_interval)
    },
    async saveChanges({getters}, payload) {
      if (getters.buildVersion == 'simubox') {
        const ip = 'http://192.168.111.100:5100/'
        try {
          await fetch(ip + 'export_database')
        } catch(err) {
          console.log(err)
          payload.vm.$toast.add({severity:'error', summary: 'Error!', detail:'Something went wrong with saving to database..', life: 5000});
        }
      }
    }
  },
  getters: {
    //list of all model signals
    //used in Breaker.vue, EasyGen.vue, SimuItem.vue
    simuSignals(state) {
      return state.signals
    },
    simuTime(state) {
      const simu_time_signals = ['Simulation_time_unit_5ms_L16', 'Simulation_time_unit_5ms_M16']
      return state.signals ? state.signals.filter(signal => simu_time_signals.includes(signal.name)) : null
    },
    //Ip of actuall connected VM
    //used in Configuration.vue and here
    virtualMachineIp(state) {
      return state.virtual_machine_ip
    },
    simulationIp(state) {
      return state.simulation_ip
    },
    simulationIpPartial(state) {

      // do poprawki !!! znaleźć inny sposób wybrania adresu IP w monitorze
      const ip_partial = state.simulation_ip.replace('https://', '').replace('/', '').split(':')
      return {ip: ip_partial[0], port: ip_partial[1].replace('api/', '')}
    },
    //number of cycles for running simulation
    //used here
    cyclesNumber(state) {
      return state.cycles
    },
    //one of three connection statuses
    //used in ConnectionCheck.vue
    connectionStatus(state) {
      return state.status
    },
    queueLength(state) {
      return state.promise_queue.length
    },
    refreshRatio(state) {
      return state.refresh_ratio
    },
    clientVersion(state, getters) {
      switch (getters.buildVersion) {
        case 'pc':
          return 'WorkingPC ' + state.app_version 
        case 'simubox':
          return 'SimuBox ' + state.app_version
        case 'cloud':
          return 'Cloud ' + state.app_version
        default:
          return 'Unknown ' + state.app_version
      }
    },
    connectedModel(state) {
      return state.connected_model
    },
    selectedRefreshRatio(state) {
      return state.selected_refresh_ratio + 'ms'
    },
    connectionLatency(state) {
      return state.latency + 'ms'
    },
    lastSequence(state) {
      return state.last_sequence
    }
  }   
});
